// eslint-disable-next-line @nx/enforce-module-boundaries
import { useEventMixpanel } from '@mixpanel/hooks/use-event-mixpanel';
import { useQueryClient } from '@tanstack/react-query';
import type { AxiosInstance } from 'axios';

import { StockAlertService } from '@core/api';
import { useStoreUtil } from '@core/context';
import {
  CreateStockAlertResponse,
  EQueryKey,
  StockAlertCreationProps,
  StockAlertResponse,
  StockAlertUpdateProps,
} from '@core/type/api';
import { HookStoreFn, StockAlertHook, StockAlertState } from '@core/type/context';
import { EMixpanelEventName } from '@core/type/mixpanel';

import { useLanguage } from './use-language';
import { useMutationApi } from './use-mutation-api';
import { useParamsQueriesKeys, useQueryApi } from './use-query-api';

export const useStockAlert: HookStoreFn<StockAlertState, StockAlertHook> = (): StockAlertHook => {
  const [axiosInstance] = useStoreUtil<AxiosInstance>('axiosInstance');
  const { language } = useLanguage();
  const { trackAlertEvent } = useEventMixpanel();

  const queryClient = useQueryClient();

  const { data: stockAlertsData } = useQueryApi<StockAlertResponse>(EQueryKey.STOCK_ALERTS);
  const queries = useParamsQueriesKeys(EQueryKey.STOCK_ALERTS);

  const { mutate: removeFromStockAlerts } = useMutationApi(
    async (stockAlertId: number) =>
      StockAlertService.deleteAlert(axiosInstance, language, stockAlertId),
    {
      mutationKey: queries,
      onSuccess: (_, alertId: number) => {
        queryClient.setQueryData(queries, (data: StockAlertResponse): StockAlertResponse => {
          const alert = data.stockAlerts.find((stockAlert) => stockAlert.id === alertId);
          trackAlertEvent(
            EMixpanelEventName.STOCK_ALERT,
            'delete',
            window.location.pathname,
            alert.notification,
            { quantityValue: alert.quantity },
            alert.product,
          );
          return {
            stockAlerts: data.stockAlerts.filter((alert) => alert.id !== alertId),
          };
        });
      },
    },
  );

  const { mutateAsync: addToStockAlerts } = useMutationApi(
    async ({ submitData }: StockAlertCreationProps) =>
      StockAlertService.createAlert(axiosInstance, language, submitData),
    {
      onSuccess: (
        alert: CreateStockAlertResponse,
        { submitData, product }: StockAlertCreationProps,
      ) => {
        queryClient.setQueryData(queries, (data: StockAlertResponse): StockAlertResponse => {
          trackAlertEvent(
            EMixpanelEventName.STOCK_ALERT,
            'create',
            window.location.pathname,
            alert.stockAlert.notification,
            { quantityValue: submitData.quantity },
            product,
          );
          return {
            stockAlerts: data.stockAlerts.concat([
              {
                ...alert.stockAlert,
                product,
                quantity: submitData.quantity,
                notification: submitData.notification,
                createdAt: new Date().toISOString(),
              },
            ]),
          };
        });
      },
    },
  );

  const { mutateAsync: updateStockAlert } = useMutationApi(
    async ({ id, ...restProps }: StockAlertUpdateProps) =>
      StockAlertService.patchAlert(axiosInstance, language, id, { ...restProps }),
    {
      onSuccess: (_, { id, quantity, notification }: StockAlertUpdateProps) => {
        queryClient.setQueryData(queries, (data: StockAlertResponse): StockAlertResponse => {
          const alert = data.stockAlerts.find((stockAlert) => stockAlert.id === id);
          trackAlertEvent(
            EMixpanelEventName.STOCK_ALERT,
            'update',
            window.location.pathname,
            notification,
            { quantityValue: quantity },
            alert.product,
          );
          return {
            stockAlerts: data.stockAlerts.map((stockAlert) =>
              stockAlert.id === id
                ? {
                    ...stockAlert,
                    quantity,
                    notification,
                  }
                : stockAlert,
            ),
          };
        });
      },
    },
  );

  return {
    stockAlerts: stockAlertsData?.stockAlerts ?? [],
    removeFromStockAlerts,
    addToStockAlerts,
    updateStockAlert,
  };
};
