import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import { Box, Button } from '@core/component';
import { Translate } from '@core/constant';
import { EButtonVariant } from '@core/type';

import { ButtonsModalAddAlertProps } from '../interface-modal-alert';

const ButtonsModalAddAlertContainer = ({ onCancel }: ButtonsModalAddAlertProps) => {
  const { t } = useTranslation(Translate.common.PRODUCT);

  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        variant={EButtonVariant.OUTLINED}
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
      >
        {t('stockAlerts.newAlertModal.btnCancel')}
      </Button>

      <Button type="submit">{t('stockAlerts.newAlertModal.btnAddAlert')}</Button>
    </Box>
  );
};

export const ButtonsModalAddAlert = memo(ButtonsModalAddAlertContainer);
