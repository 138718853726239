import { number, SchemaOf } from 'yup';

import { CreateAlertForm, createAlertSchema } from './alert';

export type StockAlertForm = CreateAlertForm<{
  quantity: number;
}>;

export const stockAlertSchema: SchemaOf<StockAlertForm> = createAlertSchema({
  quantity: number().positive().required(),
});
