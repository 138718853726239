import useTranslation from 'next-translate/useTranslation';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Grid, Modal, ModalProps, Typography, InputNumberControl } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useStockAlert, useValidator } from '@core/hook';
import {
  EContactMethod,
  ECountry,
  ETypographyVariant,
  StockAlertForm,
  stockAlertSchema,
} from '@core/type';
import { PostStockAlertEntity } from '@core/type/api';
import { getInternationalPrefix } from '@core/util';

import { AlertForm } from '../../alert-form';
import { ButtonsModalAddAlert, HeaderModal, ModalSuccessStockAlertUpdate } from './components';
import { ModalAddProductAlertProp } from './interface-modal-alert';

const ModalAddStockAlertContainer = ({
  product,
  isOpen,
  onCloseModal,
}: Omit<ModalProps, 'children'> & ModalAddProductAlertProp) => {
  const { phone } = useContextUser();
  const { addToStockAlerts } = useStockAlert();
  const { t: tForm } = useTranslation(Translate.common.FORM);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);
  const [isPhoneCountryModalOpen, setIsPhoneCountryModalOpen] = useState(false);
  const isPhonePrefilled = !!phone;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<StockAlertForm>({
    defaultValues: {
      phone: {
        countryIso: phone?.countryIso || ECountry.SWITZERLAND,
        number: phone?.number || '',
      },
      notification: EContactMethod.EMAIL,
    },
    resolver: useValidator(stockAlertSchema, tForm),
    mode: 'onChange',
  });

  const onSubmit = ({ phone: newPhone, ...data }: StockAlertForm) => {
    const submitData: PostStockAlertEntity = {
      ...data,
      productId: product?.id,
    };

    if (data.notification === EContactMethod.SMS) {
      if (phone?.code) {
        submitData.phone = phone;
      } else {
        submitData.phone = { ...newPhone, code: getInternationalPrefix(newPhone.countryIso) };
      }
    }

    return addToStockAlerts({
      submitData,
      product,
    });
  };

  const handleModalClose = () => {
    if (!isPhoneCountryModalOpen) {
      onCloseModal();
      reset();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen && !isSubmitSuccessful} onCloseModal={handleModalClose}>
        <HeaderModal
          title={tProduct('stockAlerts.newAlertModal.stockAlert')}
          subTitle={product?.name}
        />
        <Modal.Body>
          <Box display="flex" justifyContent="center" marginBottom="32px">
            <Typography variant={ETypographyVariant.BODY2}>
              {tProduct('stockAlerts.newAlertModal.letUsKnow')}
            </Typography>
          </Box>

          <AlertForm
            formProps={{
              t: tForm,
              trigger: trigger,
              reset: reset,
              handleSubmit: handleSubmit,
              setError: setError,
              fn: onSubmit,
              onSuccess: reset,
            }}
            phoneInputProps={{
              watch: watch,
              isSubmitted: true,
              control: control,
              setValue: setValue,
              disabled: isPhonePrefilled,
              isPrefilled: isPhonePrefilled,
              trigger: trigger,
              errors: errors,
              state: [isPhoneCountryModalOpen, setIsPhoneCountryModalOpen],
            }}
            actions={<ButtonsModalAddAlert onCancel={handleModalClose} />}
          >
            <Grid>
              <InputNumberControl
                control={control}
                name="quantity"
                required
                nbDecimal={0}
                label={tProduct('stockAlerts.newAlertModal.yourQuantity')}
                isError={!!errors['quantity']}
              />
            </Grid>
          </AlertForm>
          <Typography marginTop={'32px'} variant={ETypographyVariant.CAPTION3}>
            {tProduct('stockAlerts.newAlertModal.confirmYourConsent')}
          </Typography>
        </Modal.Body>
      </Modal>
      <ModalSuccessStockAlertUpdate
        name={product?.name}
        isOpen={isOpen && isSubmitSuccessful}
        onCloseModal={handleModalClose}
      />
    </>
  );
};

export const ModalAddStockAlert = memo(ModalAddStockAlertContainer);
