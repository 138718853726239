import useTranslation from 'next-translate/useTranslation';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Modal, ModalProps, Typography, Grid, InputNumberControl } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useStockAlert, useValidator } from '@core/hook';
import {
  EColor,
  EContactMethod,
  ECountry,
  ETypographyVariant,
  StockAlertForm,
  stockAlertSchema,
} from '@core/type';
import { StockAlertUpdateProps } from '@core/type/api';
import { getInternationalPrefix } from '@core/util';

import { AlertForm } from '../../alert-form';
import { ButtonsModalUpdateAlert, ModalSuccessStockAlertUpdate } from './components';
import { ModalUpdateStockAlertProps } from './interface-modal-alert';

const ModalUpdateStockAlertContainer = ({
  stockAlert,
  isOpen,
  onCloseModal,
}: Omit<ModalProps, 'children'> & ModalUpdateStockAlertProps) => {
  const { updateStockAlert, removeFromStockAlerts } = useStockAlert();
  const { phone } = useContextUser();
  const [isPhoneCountryModalOpen, setIsPhoneCountryModalOpen] = useState(false);

  const { t: tForm } = useTranslation(Translate.common.FORM);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  const isPhonePrefilled = !!phone;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<StockAlertForm>({
    defaultValues: {
      notification: stockAlert.notification,
      quantity: stockAlert.quantity,
      phone: {
        countryIso: phone?.countryIso || ECountry.SWITZERLAND,
        number: phone?.number || '',
      },
    },
    resolver: useValidator(stockAlertSchema, tForm),
    mode: 'onChange',
  });

  const deleteAlert = () => {
    removeFromStockAlerts(stockAlert.id);
    onCloseModal();
  };

  const onSubmit = ({ phone: newPhone, ...data }: StockAlertForm) => {
    const submitData: StockAlertUpdateProps = {
      ...data,
      id: stockAlert.id,
    };

    if (data.notification === EContactMethod.SMS) {
      if (phone?.code) {
        submitData.phone = phone;
      } else {
        submitData.phone = { ...newPhone, code: getInternationalPrefix(newPhone.countryIso) };
      }
    }

    return updateStockAlert(submitData);
  };

  const handleModalClose = () => {
    if (!isPhoneCountryModalOpen) {
      onCloseModal();
      if (isSubmitSuccessful) {
        // reset isSubmitSuccessful, without changing any other state
        reset(watch(), { keepErrors: true, keepDirty: true });
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen && !isSubmitSuccessful} onCloseModal={handleModalClose}>
        <Modal.Header>
          <Typography variant={ETypographyVariant.H5}>
            {tProduct('stockAlerts.newAlertModal.stockAlert')}
          </Typography>
          <Typography
            variant={ETypographyVariant.H5}
            color={{ commonType: EColor.BLACK, intensity: EColor.R900 }}
          >
            {stockAlert.product.name}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Box display="flex" marginBottom="32px">
            <Typography variant={ETypographyVariant.BODY2}>
              {tProduct('stockAlerts.editAlertModal.alreadySet')}
            </Typography>
          </Box>

          <AlertForm
            formProps={{
              t: tForm,
              trigger: trigger,
              reset: reset,
              handleSubmit: handleSubmit,
              setError: setError,
              fn: onSubmit,
              onSuccess: reset,
            }}
            phoneInputProps={{
              watch: watch,
              isSubmitted: true,
              control: control,
              setValue: setValue,
              disabled: isPhonePrefilled,
              isPrefilled: isPhonePrefilled,
              trigger: trigger,
              errors: errors,
              state: [isPhoneCountryModalOpen, setIsPhoneCountryModalOpen],
            }}
            actions={<ButtonsModalUpdateAlert onDelete={deleteAlert} onCancel={handleModalClose} />}
          >
            <Grid>
              <InputNumberControl
                control={control}
                name="quantity"
                nbDecimal={0}
                required
                label={tProduct('stockAlerts.newAlertModal.yourQuantity')}
                isError={!!errors['quantity']}
              />
            </Grid>
          </AlertForm>
        </Modal.Body>
      </Modal>
      <ModalSuccessStockAlertUpdate
        name={stockAlert.product.name}
        isOpen={isOpen && isSubmitSuccessful}
        onCloseModal={handleModalClose}
      />
    </>
  );
};

export const ModalUpdateStockAlert = memo(ModalUpdateStockAlertContainer);
